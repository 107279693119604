import React from 'react';
import LoginForm from '../components/LoginForm';
import styled from 'styled-components';

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f8f0;
`;

function LoginPage() {
  return (
    <PageContainer>
      <LoginForm />
    </PageContainer>
  );
}

export default LoginPage;
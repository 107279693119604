import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainAppPage from './pages/MainAppPage';
import LoginPage from './pages/LoginPage';
import SignUpForm from './components/SignUpForm';
import MainLoggedIn from './pages/MainLoggedIn';
import AddProductPage from './pages/AddProductPage';

// PrivateRoute component
const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('authToken');
  return token ? children : <Navigate to="/main" replace />;
};

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<MainAppPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/signup" element={<SignUpForm />} />
          <Route
            path="/main"
            element={
              <PrivateRoute>
                <MainLoggedIn />
              </PrivateRoute>
            }
          />
          <Route
            path="/add-product"
            element={
              <PrivateRoute>
                <AddProductPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
import React, { useState, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { GoogleMap, Marker } from '@react-google-maps/api';

const Container = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
`;

const Title = styled.h1`
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
`;

const Subtitle = styled.h2`
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: normal;
`;

const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  margin-bottom: 10px;
`;

const ImageUpload = styled.div`
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  position: relative;
`;

const UploadIcon = styled.span`
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  background-color: white;
  padding: 5px;
  border-radius: 50%;
`;

const Input = styled.input`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  appearance: none;
  background-image: url('data:image/svg+xml;charset=US-ASCII,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 1L7 7L13 1" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 14px auto;
`;

const PriceInput = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const PriceLabel = styled.label`
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
`;

const CancelButton = styled(Button)`
  background-color: #f0f0f0;
  color: #333;
`;

const PostButton = styled(Button)`
  background-color: #4CAF50;
  color: white;
`;

const ImageInput = styled.input`
  display: none;
`;

const ImageLabel = styled.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
  border: 2px dashed #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
  position: relative;
`;

const MapContainer = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 15px;
`;

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const defaultCenter = {
  lat: 3.140853,
  lng: 101.693207, // Default center (Kuala Lumpur)
};

const unitOptions = [
  { value: '100g', label: '100 gram' },
  { value: 'kg', label: 'Kilogram (kg)' },
  { value: 'mL', label: 'Milliliter (mL)' },
  { value: 'L', label: 'Liter (L)' }
];

function AddProductPage() {
  const [productName, setProductName] = useState('');
  const [location, setLocation] = useState(null);
  const [locationDetail, setLocationDetail] = useState('');
  const [unitMeasurement, setUnitMeasurement] = useState('');
  const [price, setPrice] = useState('');
  const [contact, setContact] = useState(''); 
  const [image, setImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [mapCenter, setMapCenter] = useState(defaultCenter);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setLocation(currentLocation);
          setMapCenter(currentLocation);
        },
        (error) => {
          console.error("Error getting location:", error);
          setLocation(defaultCenter);
          setMapCenter(defaultCenter);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
      setLocation(defaultCenter);
      setMapCenter(defaultCenter);
    }
  }, []);

  const onMapClick = useCallback((e) => {
    setLocation({
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    });
  }, []);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
      setPreviewUrl(URL.createObjectURL(file));
    }
  };

  const handleContactChange = (e) => {
    const value = e.target.value.replace(/\D/g, ''); // Remove non-digit characters
    if (value.length <= 11) {
      setContact(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!productName || !location || !locationDetail || !unitMeasurement || !price || !contact || !image) {
      setError('All fields are mandatory. Please fill in all the information.');
      return;
    }

    if (contact.length < 10) {
      setError('Contact number must be at least 10 digits long.');
      return;
    }

    const productData = {
      name: productName,
      price: parseFloat(price),
      unit: unitMeasurement,
      location: {
        lat: location.lat,
        lon: location.lng
      },
      locationDetail: locationDetail,
      contact: contact,
      image: previewUrl // Note: In a real app, you'd upload the image and use the returned URL
    };

    try {
      console.log('Sending request to server...');
      const response = await axios.post('/api/products', productData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      console.log('Product added:', response.data);
      navigate('/main');
    } catch (error) {
      console.error('Error adding product:', error);
      setError(`Error adding product: ${error.response?.data?.message || error.message}`);
    }
  };

  const handleCancel = () => {
    navigate('/main');
  };

  return (
    <Container>
      <Title>Keboon.</Title>
      <Subtitle>Add Product</Subtitle>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSubmit}>
        <ImageLabel>
          {previewUrl ? (
            <img src={previewUrl} alt="Preview" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          ) : (
            <UploadIcon>Upload Image ↑</UploadIcon>
          )}
          <ImageInput
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            required
          />
        </ImageLabel>
        <Input
          placeholder="Produce Name"
          value={productName}
          onChange={(e) => setProductName(e.target.value)}
          required
        />
        <MapContainer>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={mapCenter}
            zoom={15}
            onClick={onMapClick}
          >
            {location && <Marker position={location} />}
          </GoogleMap>
        </MapContainer>
        <TextArea
          placeholder="Location Detail"
          value={locationDetail}
          onChange={(e) => setLocationDetail(e.target.value)}
          required
        />
        <Select
          value={unitMeasurement}
          onChange={(e) => setUnitMeasurement(e.target.value)}
          required
        >
          <option value="">Unit Measurement</option>
          {unitOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
        <PriceInput>
          <PriceLabel>Price/ Unit :</PriceLabel>
          <Input
            type="number"
            placeholder="RM"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
          />
        </PriceInput>
        <Input
          type="tel"
          placeholder="Contact No. (e.g., 0123456789)"
          value={contact}
          onChange={handleContactChange}
          pattern="[0-9]{10,11}"
          title="Phone number must be 10-11 digits"
          required
        />
        <h6>Ensure all information is accurate to avoid rejection by the system admin.</h6>
        <ButtonContainer>
          <CancelButton type="button" onClick={handleCancel}>Cancel</CancelButton>
          <PostButton type="submit">Post (RM0.10)</PostButton>
        </ButtonContainer>
      </form>
    </Container>
  );
}

export default AddProductPage;
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const MainContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.h1`
  font-size: 24px;
  font-weight: bold;
`;

const NavLinks = styled.div`
  display: flex;
  gap: 20px;
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: #333;
  &:hover {
    text-decoration: underline;
  }
`;

const LogoutButton = styled.button`
  padding: 8px 16px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const SearchBar = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
  margin-bottom: 20px;
`;

const MapPlaceholder = styled.div`
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DateGroup = styled.div`
  margin-bottom: 20px;
`;

const DateHeader = styled.h2`
  font-size: 18px;
  margin-bottom: 10px;
`;

const ProductCard = styled.div`
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  position: relative;
`;

const ProductImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
`;

const ProductInfo = styled.div`
  flex: 1;
`;

const ProductName = styled.h3`
  margin: 0 0 5px 0;
`;

const ProductPrice = styled.p`
  margin: 0 0 5px 0;
  font-weight: bold;
`;

const ProductLocation = styled.p`
  color: #4CAF50;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const ProductContact = styled.a`
  color: #4CAF50;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const DistanceTag = styled.span`
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #f0f0f0;
  padding: 2px 5px;
  border-radius: 3px;
  font-size: 12px;
`;

const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: ${props => props.active ? '#4CAF50' : 'white'};
  color: ${props => props.active ? 'white' : 'black'};
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

function MainLoggedIn() {
  const [products, setProducts] = useState([]);
  const [search, setSearch] = useState('');
  const [apiUrl, setApiUrl] = useState('');
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 10;

  const fetchApiConfig = useCallback(async () => {
    try {
      const response = await axios.get('/api/config');
      setApiUrl(response.data.backendUrl);
    } catch (error) {
      console.error('Error fetching API config:', error);
    }
  }, []);

  const fetchProducts = useCallback(async () => {
    if (!apiUrl) return;
    try {
      const response = await axios.get(`${apiUrl}/api/products`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        }
      });
      const sortedProducts = response.data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setProducts(sortedProducts);
    } catch (error) {
      console.error('Error fetching products:', error);
      if (error.response && error.response.status === 401) {
        handleLogout();
      }
    }
  }, [apiUrl]);

  useEffect(() => {
    fetchApiConfig();
  }, [fetchApiConfig]);

  useEffect(() => {
    if (apiUrl) {
      fetchProducts();
      const intervalId = setInterval(fetchProducts, 10000);
      return () => clearInterval(intervalId);
    }
  }, [apiUrl, fetchProducts]);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(search.toLowerCase())
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const groupProductsByDate = (products) => {
    const groups = {};
    products.forEach(product => {
      const date = new Date(product.date).toDateString();
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(product);
    });
    return groups;
  };

  const formatDate = (dateString) => {
    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-US', options);
  };

  const handleLocationClick = (lat, lon) => {
    window.open(`https://www.google.com/maps?q=${lat},${lon}`);
  };

  const handleLogout = () => {
    localStorage.removeItem('authToken');
    navigate('/');
  };

  const groupedProducts = groupProductsByDate(currentProducts);

  const pageCount = Math.ceil(filteredProducts.length / productsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <MainContainer>
      <Header>
        <Logo>Keboon.</Logo>
        <NavLinks>
          <NavLink to="/main">Wallet</NavLink>
          <NavLink to="/add-product">Add Product</NavLink>
          <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
        </NavLinks>
      </Header>
      <SearchBar
        type="text"
        placeholder="Search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <MapPlaceholder>Map will be integrated here</MapPlaceholder>
      {Object.entries(groupedProducts).map(([date, dateProducts]) => (
        <DateGroup key={date}>
          <DateHeader>{formatDate(date)}</DateHeader>
          {dateProducts.map(product => (
            <ProductCard key={product._id}>
              <ProductImage src={product.image} alt={product.name} />
              <ProductInfo>
                <ProductName>{product.name}</ProductName>
                <ProductPrice>RM{product.price}/{product.unit}</ProductPrice>
                <ProductLocation onClick={() => handleLocationClick(product.location.lat, product.location.lon)}>
                  {product.locationDetail}
                </ProductLocation>
                <ProductContact href={`tel:${product.contact}`}>{product.contact}</ProductContact>
              </ProductInfo>
            </ProductCard>
          ))}
        </DateGroup>
      ))}
      <PaginationContainer>
        {[...Array(pageCount)].map((_, i) => (
          <PageButton
            key={i + 1}
            onClick={() => handlePageChange(i + 1)}
            active={currentPage === i + 1}
          >
            {i + 1}
          </PageButton>
        ))}
      </PaginationContainer>
    </MainContainer>
  );
}

export default MainLoggedIn;